<template>
  <component
    v-model="open"
    :is="currentComponent"
    :class="{ 'w-100 mr-xl-3': !(windowWidth <= 1234) }"
    @search="search"
    @reset="resetFilterParams"
    @click.self="
      () => {
        $emit('reset');
      }
    "
    v-if="internalFilterParams"
  >
    <form
      style="overflow-x: hidden"
      @keydown.enter="search"
      @submit.prevent="search"
      class="
        mt-2 mt-md-0
        review-wrapper
        container
        bg-lg-white
        rounded-0 rounded-md
        mr-2
        ml-0
        px-0
        pt-0 pt-lg-3 pt-lg-2
        h-100
      "
      :class="{ 'review-wrapper--open': true, 'w-100 rectangle-block': !(windowWidth <= 1234) }"
      data-scroll-speed="70"
    >
      <!-- <template v-if="windowWidth <= 1234">
        <p class="border-bottom px-4 py-3" style="font-size: 20px">Фильтры</p>
        <div
          class="border-bottom px-4 py-4 d-flex align-items-center justify-content-between"
          style="font-size: 16px"
        >
          <div>Показывать по</div>
          <div class="select-wrapper" style="width: 58px">
            <select class="select" v-model="internalFilterParams.page_size">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>  
            </select>
          </div>
        </div>
        <div class="ml-3 py-3">
          <b-btn variant="outline-secondary" class="mr-2" @click="resetFilterParams()"
            >Сбросить</b-btn
          >
          <b-btn @click="search" variant="yellow">Найти</b-btn>
        </div>
      </template> -->
      <div class="row w-100 px-3 mb-4 ml-0 mr-0">
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group
            class="review-filter-fieldset"
            label-class="ws-nowrap"
            label="Брали рекламу у блогера"
          >
            <b-form-input
              placeholder="Введите ник блогера"
              v-model="internalFilterParams.executor"
            />
          </b-form-group>
        </div>
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group
            label-class="ws-nowrap"
            label="Цена за рекламу в ₽"
            class="review-filter-fieldset"
          >
            <div class="row">
              <div class="col pr-1">
                <b-form-input
                  type="number"
                  placeholder="от 450"
                  v-model="internalFilterParams.price_min"
                />
              </div>
              <div class="col pl-1">
                <b-form-input
                  type="number"
                  placeholder="до 99000"
                  v-model="internalFilterParams.price_max"
                />
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group
            label-class="ws-nowrap"
            label="Приход подписчиков"
            class="review-filter-fieldset"
          >
            <div class="row">
              <div class="col pr-1">
                <b-form-input
                  type="number"
                  placeholder="от 20"
                  v-model="internalFilterParams.arrival_min"
                />
              </div>
              <div class="col pl-1">
                <b-form-input
                  type="number"
                  placeholder="до 56 000"
                  v-model="internalFilterParams.arrival_max"
                />
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group>
            <div
              style="margin-top: 4px"
              class="option"
              :class="{
                'option--active': internalFilterParams.price_meta.indexOf('together') !== -1,
              }"
              @click="togglePriceMeta('together')"
            >
              <div class="option__select"></div>
              <span class="option__title">Взаимопиар</span>
            </div>
            <div
              class="option"
              :class="{
                'option--active': internalFilterParams.price_meta.indexOf('barter') !== -1,
              }"
              @click="togglePriceMeta('barter')"
            >
              <div class="option__select"></div>
              <span class="option__title">Бартер</span>
            </div>
            <div
              class="option"
              :class="{ 'option--active': internalFilterParams.valid_advertiser_blogger }"
              @click="
                (val) => {
                  internalFilterParams.valid_advertiser_blogger =
                    !internalFilterParams.valid_advertiser_blogger;
                }
              "
            >
              <div class="option__select"></div>
              <span class="option__title">Только блогеры со статистикой</span>
            </div>
            <div
              class="option d-md-none"
              :class="{ 'option--active': disclosedReview }"
              @click="disclosedReview = !disclosedReview"
            >
              <div class="option__select"></div>
              <span class="option__title">Раскрывать все отзывы</span>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row w-100 px-3 mb-4 ml-0 mr-0">
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group
            label-class="ws-nowrap"
            class="review-filter-fieldset"
            label="Темы заказчиков рекламы"
          >
            <multiselect
              :options="tags"
              placeholder="Выберите темы"
              select-label=""
              deselectLabel=""
              selectedLabel=""
              label="name"
              track-by="id"
              :searchable="false"
              :multiple="true"
              :value="tags.filter((val) => internalFilterParams.tags.includes(val.id))"
              @input="
                (value) => {
                  internalFilterParams.tags = value.map((val) => val.id);
                }
              "
            >
              <span slot="noResult">Тема не найдена</span>
              <template #tagPlaceholder>Выберите тему</template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group
            label-class="ws-nowrap"
            label="Цена за подписчика в ₽"
            class="review-filter-fieldset"
          >
            <div class="row">
              <div class="col pr-1">
                <b-form-input
                  type="number"
                  placeholder="от 1"
                  v-model="internalFilterParams.price_per_one_min"
                />
              </div>
              <div class="col pl-1">
                <b-form-input
                  type="number"
                  placeholder="до 400"
                  v-model="internalFilterParams.price_per_one_max"
                />
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-xl-3 col-12 pl-0 review-filter-col">
          <b-form-group
            label-class="ws-nowrap"
            label="Формат рекламы"
            class="review-filter-fieldset"
          >
            <multiselect
              :options="reviewsOptions.actions.POST.ad_type.choices"
              placeholder="Выберите формат"
              select-label=""
              deselectLabel=""
              selectedLabel=""
              label="display_name"
              :searchable="false"
              track-by="value"
              v-model="internalFilterParams.ad_type"
            >
              <template slot="tagPlaceholder">Выберите формат</template>
            </multiselect>
          </b-form-group>
        </div>
        <div
          class="
            col-xl-3 col-12
            pl-0
            d-flex
            align-items-end
            justify-content-between
            mb-3
            d-none d-md-flex
          "
          v-if="!(windowWidth <= 1234)"
        >
          <div style="margin-bottom: 1px" class="row w-100">
            <div class="col-6 pr-1">
              <b-button variant="yellow" @click="search" class="w-100">Найти</b-button>
            </div>
            <div class="col-6 pl-1">
              <b-button
                variant="outline-secondary"
                class="w-100"
                @keyup.ctrl.88="resetFilterParams()"
                @click="resetFilterParams()"
                >Сбросить</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div
        :style="{
          'border-color': extraFilters ? '#D5DFE4' : 'transparent',
          'border-bottom': '1px solid #D5DFE4',
          'margin-bottom': extraFilters ? '20px !important' : '0px !important',
        }"
        style="transition: margin 0.5s ease, border 0.5s ease"
        class="filters__next--text row w-100 px-3 mb-4 ml-0 mr-0 d-none d-xl-block"
      >
        <div class="col-12 pl-0">
          <span> Фильтры по охватам, странам, городам, полу и возрасту аудитории </span>
          <span @click="extraFilters = !extraFilters" class="show--filters">{{
            extraFilters ? 'Скрыть ↑' : 'Показать ↓'
          }}</span>
        </div>
      </div>
      <b-collapse id="collapse-1" :visible="extraFilters || windowWidth <= 1234">
        <div style="position: relative">
          <div v-if="!currentUser || !currentUser.services_subscriber" class="pay__filters">
            <p class="pay__filters--text">Разблокируются в полной версии отзывов</p>
            <b-button
              href="https://sub.easyprbot.com/#tarrifs"
              target="_blank"
              class="pay--filters_btn"
              variant="warning"
              >Купить доступ от 2990₽ в месяц</b-button
            >
          </div>
          <div
            style="background: white; opacity: 0.5"
            v-if="!currentUser || !currentUser.services_subscriber"
            class="filter__unsub--cover"
          ></div>
          <div class="d-flex flex-wrap w-100 px-3 mr-0 ml-0">
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Cтрана аудитории">
                <div class="d-flex flex-row review-filter-fieldset">
                  <multiselect
                    :options="countries"
                    placeholder="Выберите страну"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    label="name"
                    track-by="id"
                    :searchable="true"
                    :value="countries.filter((val) => val.id === internalFilterParams.country)"
                    @input="
                      (val) => {
                        internalFilterParams.country = val.id;
                      }
                    "
                  >
                    <span slot="noResult">Страна не найдена</span>
                    <template #tagPlaceholder>Выберите страну</template>
                  </multiselect>

                  <b-form-input
                    class="pcnt--inp"
                    type="number"
                    placeholder="от  %"
                    v-model="internalFilterParams.country_percent"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Город аудитории">
                <div class="d-flex flex-row review-filter-fieldset">
                  <CitySelect
                    v-model="internalFilterParams.city"
                    deselectLabel=""
                    selectedLabel=""
                  />
                  <b-form-input
                    class="pcnt--inp"
                    type="number"
                    placeholder="от   %"
                    v-model="internalFilterParams.city_percent"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Возраст аудитории">
                <div class="d-flex flex-row review-filter-fieldset">
                  <multiselect
                    :options="options.audiences.child.children.age_group.choices"
                    placeholder="Выберите возраст"
                    select-label=""
                    deselectLabel=""
                    selectedLabel=""
                    label="display_name"
                    track-by="value"
                    :searchable="false"
                    :value="
                      options.audiences.child.children.age_group.choices.filter(
                        (val) => val.value === internalFilterParams.audience
                      )
                    "
                    @input="
                      (val) => {
                        internalFilterParams.audience = val.value;
                      }
                    "
                  >
                    <span slot="noResult">Возраст не найден</span>
                    <template #tagPlaceholder>Выберите возраст</template>
                  </multiselect>

                  <b-form-input
                    class="pcnt--inp"
                    type="number"
                    placeholder="от   %"
                    v-model="internalFilterParams.age_percent"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Пол аудитории">
                <b-form-checkbox
                  :checked="internalFilterParams.females_percent_min == 80"
                  @change="
                    () => {
                      internalFilterParams.females_percent_min = 80;
                      internalFilterParams.females_percent_max = null;
                    }
                  "
                >
                  От 80% женщин
                </b-form-checkbox>
                <b-form-checkbox
                  :checked="internalFilterParams.females_percent_max == 60"
                  @change="
                    () => {
                      internalFilterParams.females_percent_max = 60;
                      internalFilterParams.females_percent_min = null;
                    }
                  "
                >
                  От 40% мужчин
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="d-flex flex-wrap w-100 px-3 mr-0 ml-0">
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Охваты сторис">
                <div class="d-flex flex-row review-filter-fieldset">
                  <b-form-input
                    v-model="internalFilterParams.coverage_stories_min"
                    type="number"
                    placeholder="От"
                  />
                  <b-form-input
                    class="ml-2"
                    v-model="internalFilterParams.coverage_stories_max"
                    type="number"
                    placeholder="До"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Охваты постов">
                <div class="d-flex flex-row review-filter-fieldset">
                  <b-form-input
                    v-model="internalFilterParams.coverage_posts_min"
                    type="number"
                    placeholder="От"
                  />

                  <b-form-input
                    class="ml-2"
                    v-model="internalFilterParams.coverage_posts_max"
                    type="number"
                    placeholder="До"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-12 pl-0 review-filter-col">
              <b-form-group label-class="ws-nowrap" label="Обновление карточек">
                <multiselect
                  class="review-filter-fieldset"
                  :options="updatePeriods"
                  placeholder="Выберите период"
                  select-label=""
                  deselectLabel=""
                  selectedLabel=""
                  label="name"
                  :searchable="false"
                  track-by="value"
                  :value="
                    internalFilterParams.screens_last_updated
                      ? JSON.parse(internalFilterParams.screens_last_updated)
                      : null
                  "
                  @input="
                    (val) => {
                      internalFilterParams.screens_last_updated = JSON.stringify(val);
                    }
                  "
                >
                  <template slot="tagPlaceholder">Выберите период</template>
                </multiselect>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>
    </form>
  </component>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Multiselect from 'vue-multiselect';
import CitySelect from '@main/components/reusable/elements/selects/CitySelect.vue';
import EventBus from '../../event-bus';
import Div from '../reusable/elements/Div.vue';
import Modal from '../reusable/modals/Modal.vue';
import BaseFilterModal from '../reusable/modals/BaseFilterModal.vue';

export default {
  components: {
    Multiselect,
    CitySelect,
  },
  model: {
    prop: 'filterParams',
    event: 'input',
  },
  props: {
    filterParams: Object,
  },
  computed: {
    ...mapState([
      'currentUser',
      'tags',
      'countries',
      'cities',
      'bloggerOptions',
      'windowWidth',
      'reviewsOptions',
    ]),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    currentComponent() {
      return this.windowWidth <= 1234 ? BaseFilterModal : Div;
    },
  },
  data() {
    return {
      extraFilters: false,
      disclosedReview: false,
      selectedTags: [],
      adType: null,
      updatePeriods: [
        { name: '2 недели', value: { days: 14 } },
        { name: '1 месяц', value: { days: 30 } },
        { name: '2 месяца', value: { days: 60 } },
        { name: '3 месяца', value: { days: 90 } },
        { name: '6 месяцев', value: { days: 180 } },
      ],
      internalFilterParams: {},
      open: false,
    };
  },
  watch: {
    disclosedReview(val) {
      this.$emit('disclosed', val);
    },
    filterParams: {
      deep: true,
      immediate: true,
      handler(val) {
        this.internalFilterParams = this.lodash.cloneDeep(val);
      },
    },
    extraFilters(val) {
      localStorage.setItem('extra--filters', val);
    },
  },
  methods: {
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    ...mapMutations(['setBackMode']),
    resetFilterParams() {
      this.$emit('reset');
      this.open = false;
    },
    setFilterParams(data) {
      this.$emit('input', { ...this.internalFilterParams, ...data });
    },
    togglePriceMeta(n) {
      const index = this.internalFilterParams.price_meta.indexOf(n);
      if (index !== -1) this.internalFilterParams.price_meta.splice(index, 1);
      else this.internalFilterParams.price_meta.push(n);
    },
    async search() {
      await this.setFilterParams({});
      await this.$emit('search');
      this.open = false;
    },
  },
  mounted() {
    this.extraFilters = localStorage.getItem('extra--filters') || false;
    this.$bus.$on('review-filter-open', () => {
      this.open = true;
    });
  },
};
</script>

<style scoped lang="scss">
.col-other {
  width: 200px !important;
  margin-left: 20px !important;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

::v-deep .multiselect {
  min-height: 33.5px !important;
}

::v-deep .multiselect__tags {
  min-height: 33.5px !important;
}

.filter__unsub--cover {
  z-index: 20 !important;
  position: absolute !important;
  width: 100% !important;
  height: 80% !important;
  bottom: 10px;
}
@media (max-width: 768px) {
  .filter__unsub--cover {
    height: 84% !important;
  }
  .review-filter-col {
    padding-right: 0px !important;
  }
}
.pay__filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 15px;
  margin-bottom: 25px;
  z-index: 35;
  opacity: 1;
}
.pay__filters--text {
  margin-right: 15px;
}
.pcnt--inp {
  max-width: 60px !important;
  margin-left: 7px;
}
@media (max-width: 768px) {
  .pay__filters {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 70px;
    margin-right: 0px;
  }
  .pay__filters--text {
    font-size: 16px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .pay--filters_btn {
    font-size: 13px;
  }
}
@media (max-width: 330px) {
  .pay__filters--text {
    font-size: 12px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .pay--filters_btn {
    font-size: 12px;
  }
}
.percentage__form {
  .percentage__select--type {
    width: 204px !important;
    padding-right: 0px;
  }
  .percentage__input--percent {
    padding: 0px;
    margin-left: 4px;
    margin-right: 15px;
  }
  input {
    min-height: 33.5px !important;
    padding: 0 5px;
    text-align: right;
  }
}
@media (max-width: 768px) {
  .standard__input--mobile {
    padding: 0px !important;
    margin: 0px !important;
  }
  .standard__input--mobile:not(:first-child) {
    margin-left: 10px !important;
  }
  .percentage__input {
    max-width: 50px !important;
  }
  .percentage__form {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.percentage__input--percent {
  height: 33.5px !important;
}
.custom-control {
  padding-left: 2.09rem !important;
}

::v-deep .option--active .option__select::after {
  left: 5px;
}
@media (max-width: 768px) {
  ::v-deep .option--active .option__select::after {
    width: 6px !important;
    height: 13px !important;
    left: 8.4px !important;
  }
}
::v-deep .custom-control {
  padding-left: 14px !important;
}
::v-deep .side-modal {
  background: rgba(0, 0, 0, 0.3);
  padding-left: 0px !important;
}

::v-deep .side-modal .modal-dialog {
  margin: 0;
}

::v-deep .modal-content {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

::v-deep .side-modal .modal-content {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  max-width: 320px;
}

::v-deep .side-modal .review-wrapper {
  box-shadow: none !important;
}

@media (min-width: 1234px) {
  ::v-deep .review-wrapper {
    overflow: inherit !important;
  }
}
@media (min-width: 1234px) {
  .review-filter-fieldset {
    max-width: 83% !important;
  }
}
</style>
