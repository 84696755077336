<template>
  <modal
    ref="modal"
    :show="value"
    @hide="() => $emit('input', false)"
    :topLess="true"
    :title="'Фильтры'"
    :displayClose="windowWidth > 768"
    :wrapClass="`modal-backed-filters ${windowWidth > 768 ? 'h-75' : ''}`"
    :contentClass="'h-100 filter-modal-overheight'"
  >
    <div
      style="box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1); z-index: 99999999999999"
      class="d-sticky mb-2"
    >
      <div class="d-flex justify-content-between align-items-center px-3 py-3 my-md-1 pr-md-5">
        <h4>Фильтры</h4>
        <div>
          <b-button @click="() => $emit('reset')" class="mr-3 ph-36" variant="outline-default"
            >Сбросить</b-button
          ><b-button @click="() => $emit('search')" class="ph-36" variant="yellow">Найти</b-button>
        </div>
      </div>
    </div>
    <div class="h-100" style="overflow-x: hidden !important">
      <slot></slot>
    </div>
  </modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Modal from './Modal.vue';

export default {
  props: {
    value: Boolean,
  },
  components: { Modal },
  computed: {
    ...mapState(['windowWidth']),
  },
  methods: {
    ...mapMutations(['setBackMode']),
  },
  watch: {
    value(val) {
      if (this.windowWidth > 768) {
        return;
      }
      if (val) {
        this.setBackMode({
          handler: () => this.$emit('input', false),
          style: 'border-bottom: 1px solid #D5DFE4; box-shadow: none !important',
        });
        return;
      }
      this.setBackMode(false);
    },
  },
};
</script>

<style lang="scss">
.filter-modal-overheight {
  padding-bottom: 65px;
}
@media (max-width: 768px) {
  .modal-backed-filters {
    padding-top: 75px;
  }
}
</style>
